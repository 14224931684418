<template>
  <div class="wrapper">
    <header>输入{{formData.khmc}}的发货单</header>
    <!-- 选品名,输数量单价 品名增删改-->
    <div class="split">
      <div style="width:70%;">
        <el-input v-model="formData.shdz" placeholder="送货地址" style="width:60%;"></el-input>
        <el-input v-model="inputVal3" @input="hh_inputChange" placeholder="本页搜索" style="margin-left:10px;width:30%;"></el-input>
      </div>
      <div class=" flex-col text-right" style="width:30%;">
        <div >
          <span>合计:{{formData.kd}}</span>
        </div>
        <div >
          <span @click="qh_display_Mode" class="text-xl text-amber-500" style="width:30%;">
            <span v-if="displayMode =='表格显示'" class=" text-amber-500"><el-icon ><List /></el-icon>卡片</span>
            <span v-else class=" text-teal-500"><el-icon ><Grid /></el-icon>表格</span>
          </span>
        </div>
      </div>
      
    </div>
    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
        <lzqTable v-if="displayMode =='表格显示'" 
          :tableData_hh_dh="tableData_xm_gx" mytitle="输入发货单表头" 
          :IsSelection="false" RowKey="item6"
          @tableRowClick="tableRowClick($event)"
          @handleSelectionChange="handleSelectionChange($event)"
        >
        </lzqTable>
        <div v-if="displayMode =='卡片显示'">
          <el-card class="box-card" v-for="(item,index) in tableData_xm_gx" :key="index" @click="tableRowClick(item)" style="margin:6px;">
            <template #header>
              <div class="card-header">
                <span class="text-3xl text-amber-500">品名:{{item.item1}}</span>
              </div>
            </template>
            <span v-for="(list,indx) in item" :key="indx" class="text item">
              <span v-if="indx>='item2' && indx<='item3'" class="text-2xl text-teal-500">{{ list }}/</span>
            </span>
          </el-card>
        </div>
      </div>
    </section>
    <!-- 中间单据弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_dj" width="90%">
      <el-row justify="center">
        <el-col :span="10"><span class="mybt">{{infoTxt}}</span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="日期" label-width="90px">
            <el-date-picker v-model="formData.rq" type="date" format="YYYY-MM-DD"
              value-format="YYYY-MM-DD" style="width: 150px"></el-date-picker>
          </el-form-item>  
          <el-form-item label-width="55px">
            <span slot="label" style="color:blue;text-decoration:underline;" @click="displayPmselect">
              <i class="el-icon-edit"></i>品名</span>
            <el-input v-model="formData.pm" style="width:150px"></el-input>
          </el-form-item>        
          <!-- <el-form-item label="品名" label-width="90px"><el-input v-model="formData.pm" style="width:150px" /> </el-form-item> -->
          <el-form-item label="数量" label-width="90px"><el-input type="number" v-model="formData.sl" style="width:150px" /> </el-form-item>
          <el-form-item label="单价" label-width="90px"><el-input type="number" v-model="formData.dj" style="width:150px" /> </el-form-item>
          <el-form-item label="金额" label-width="90px"><el-input type="number" v-model="formData.je" style="width:150px" /> </el-form-item>
          <el-form-item label="单位" label-width="90px">
            <el-select v-model="formData.dw" collapse-tags style="width: 150px" placeholder="计量单位"
              @change="SelectOption_zh" size="small" no-data-text="暂无数据">
              <el-option v-for="item in zh_options" :key="item.item1" :label="item.item1" :value="item.item1" />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="10"><el-button type="success" @click="my_save_dj" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间单据弹窗结束 -->
    <!-- 中间选择修改删除弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_xgsctj" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-green-600">{{formData.khmc}}</span></span></el-col>
        <el-col ><span class="mybt"><span class="text-red-600">请选择操作:</span></span></el-col>
      </el-row>   
      <el-row justify="center">
        <el-col :span="8"><el-button type="warning" @click="btn_xg_sc_qx('修改')" :loading="myloading">修改{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="danger" @click="btn_xg_sc_qx('删除')" :loading="myloading">删除{{formData.qxxm}}</el-button></el-col>
        <el-col :span="8"><el-button type="success" @click="btn_xg_sc_qx('取消')" :loading="myloading">取消{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间选择修改删除弹窗结束 -->
    <!-- 中间填写品名资料弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_kfzl" width="90%">
      <el-row justify="center">
        <el-col ><span class="mybt"><span class="text-red-600">{{infoTxt}}</span></span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="品名" label-width="90px"><el-input v-model="formData.pm" style="width:150px" /> </el-form-item>
          <el-form-item label="单位" label-width="90px"><el-input v-model="formData.dw" style="width:150px" /> </el-form-item>
          <el-form-item label="单价" label-width="90px" type="number"><el-input v-model="formData.dj" style="width:150px" /> </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="12"><el-button type="success" @click="save_add_xg_jczl" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间填写品名资料弹窗结束 -->
    <!-- 右弹品名资料开始 -->
    <el-drawer v-model="drawer_RtoL_pm" title="查看品名" :with-header="false" size="90%">
      <el-button type="danger" @click="drawer_RtoL_pm = !drawer_RtoL_pm">关闭</el-button><i class="el-icon-plus pl-4 text-red-500" @click="xzcpmc"></i>
      <lzqTable :tableData_hh_dh="tableData_ckrbb" 
      mytitle="调用产品基础资料" :IsSelection="false" RowKey="item1"
        :Myheight="MYheight2" @tableRowClick="jczlList_Click($event)"></lzqTable>
    </el-drawer>
    <!-- 右弹品名资料结束 -->

    <!-- 底部开始 -->
    <footer>
      <div class="mybutton">
        <el-row class="mb-4">
          <el-button type="danger" @click="toxgsc">修改删除</el-button>
          <el-button type="success" @click="btn_add_jczl">新增一行</el-button>
          <el-button type="warning" @click="goBack">返回</el-button>
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部结束 -->
  </div>
</template>

<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import data_js from "../../myapi/formData.js"; 
import { ref, reactive, onMounted, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import lzqTable from '../truecomponents/lzqTable.vue'//引入自定义组件
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const tableData_ckrbb = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb_base = reactive([]); //注意这个定义,搞得半死!!
const route = useRoute();
const router = useRouter();
const dialogFormVisible_dj = ref(true);
const dialogFormVisible_pm = ref(false);
const dialogFormVisible_xgsctj = ref(false);
const dialogFormVisible_kfzl = ref(false);
const drawer_RtoL_kh = ref(false);
const drawer_RtoL_pm = ref(false);
const myloading = ref(false);
const my_row = ref()
const infoTxt = ref("新增一行");
const displayMode = ref("卡片显示");
const inputVal3 = ref("");

const dh = dateglobalfun.MydateTime();
const zh_options = reactive([]); //注意这个定义,搞得半死!!
const MYheight2 = ref(600);
let formData = ref({})
const oldCztj = ref("");//旧的查找条件

const displayPmselect = () =>{//调用产品基础资料
  drawer_RtoL_pm.value=true
  oldCztj.value = formData.value.cztj
  formData.value.cztj = "调用产品基础资料"
  khjczl_get();
}

const qh_display_Mode = () =>{//切换显示
  if (displayMode.value=='表格显示') {
    displayMode.value='卡片显示'
  }else{
    displayMode.value='表格显示'
  }
}
const xzcpmc = () =>{
  dialogFormVisible_kfzl.value = true
}
const save_add_xg_jczl = () => {//保存新增的客户资料
  console.log('保存save_add_xg_jc8zl',formData.value);
  if (formData.value.khmc == "") {
    ElMessage({ message: '名称不能为空,保存不成功!', type: 'error', });
    return;
  }
  let cansavekh = 'yes';  
  if (cansavekh == 'no') {
    dialogFormVisible_kfzl.value = false;//隐藏弹窗
    formData.value.khmc = ""; formData.value.shdz = "";formData.value.dh = "";
    return;
  }
  myloading.value = true 
  formData.value.cztj ="手机端新增产品资料"
  khjczl_get();
  dialogFormVisible_kfzl.value = false;
  // setTimeout(() => {
  //   formData.value.cztj = "保存一行发货单";
  //   khjczl_get();
  // }, 500);
  // setTimeout(() => {
  //     tableData_xm_gx.forEach(element => {
  //       formData.value.jgkhid=element.item1;
  //     });
  //     router.push({
  //     path: "dj_fhd_2",
  //     query: {
  //       my_row: JSON.stringify({item1:formData.value.khmc,item2:formData.value.shdz,item3:formData.value.jgkhid,}),
  //     },
  //   });
  // }, 500);
} 

const jczlList_Click =(row) =>{//点击右列表
  formData.value.cztj = oldCztj.value 
  formData.value.pm=row.item1
  formData.value.jgcpid=row.item4
  console.log(formData.value);
  drawer_RtoL_pm.value=false
}
watchEffect(() => {
  const x1 = formData.value.sl;
  const x2 = formData.value.dj;
  if(formData.value.sl*1.00 != 0.00){
    formData.value.je = parseFloat((x1 * x2).toFixed(2))//parseInt(x1 * x2) + parseInt(x3 == "" ? 0 : x3);
  }
});

onMounted(() => {//页面进入时
  formData.value=data_js.formData_js;
  console.log(JSON.parse(route.query.my_row),formData.value);
  my_row.value=JSON.parse(route.query.my_row)
  formData.value.dh=dh//dateglobalfun.MydateTime()
  formData.value.khmc=my_row.value.item1
  formData.value.shdz=my_row.value.item2
  formData.value.jgkhid=my_row.value.item3//加工客户id
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm"); //姓名
  formData.value.gjgx = localStorage.getItem(myGtdatafun.XMMC + "gx"); //工价工序
  setTimeout(() => {
    formData.value.cztj = "初始获取计量单位基础资料";
    khjczl_get_zh();
  }, 1000);
  setTimeout(() => {
    formData.value.cztj = "保存一行发货单";
  }, 1000);
  
});
const khjczl_get_zh = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      console.log(formData.value.cztj, response);
      myloading.value = false
      console.log('1',response,response);
      if (response.length <= 0) { return }
      zh_options.length = 0;//清空原来接收部门
      response.forEach((item) => {
        zh_options.push(item); //注1意这种写法,搞得半死!!
      });
        
    })
    .catch(function (error) {
    });
};
const SelectOption_zh = (Optionvalue) => {
  formData.value.dw = Optionvalue;//计量单位
};

const btn_xg_sc_qx = (tjstr) => {
  switch (tjstr) {
    case "修改":
      infoTxt.value="修改资料"
      formData.value.cztj = "保存修改一行发货单";
      dialogFormVisible_dj.value = true
      dialogFormVisible_xgsctj.value = false
    break;
    case "删除":
      dialogFormVisible_xgsctj.value = false
      ElMessageBox.confirm(
        "[" + formData.value.khmc  + "]确认删除吗?",
        "确认删除吗?",
        {confirmButtonText: "删除",cancelButtonText: "取消",type: "error",}
      )
        .then(() => {
          formData.value.cztj = "删除一行发货单";
          khjczl_get()
        })
        .catch(() => {console.log('取消删除一行');dialogFormVisible_xgsctj.value = false});
    break;
    case "取消":
      dialogFormVisible_xgsctj.value = false
    break;
  }  
}

const btn_add_jczl = () => {
  infoTxt.value="新增一行"
  formData.value.pm=""
  formData.value.dw=""
  formData.value.dj=""
  formData.value.id=""
  formData.value.cztj = "保存一行发货单";
  dialogFormVisible_dj.value = true
};
 
const my_save_dj = () => {//保存单据
  let item={}
  console.log('m1y_add_xg',formData.value);
  let cansavekh = 'yes';
  if (formData.value.pm == "" ) {
    ElMessage({ message: '品名不能为空,保存不成功!', type: 'error', });
    return;
  }  
  if (formData.value.sl == "" && formData.value.je == "") {
    ElMessage({ message: '数量金额不能都为空,保存不成功!', type: 'error', });
    return;
  }
  if (formData.value.sl == "") {formData.value.sl =0}
  if (formData.value.je == "") {formData.value.je =0}
  myloading.value = true
  // item.item1=formData.value.pm
  // item.item2=formData.value.sl
  // item.item3=formData.value.dj
  // tableData_xm_gx.push(item);
  // tableData_xm_gx_base.push(item);
  dialogFormVisible_dj.value=false
  khjczl_get();
} 

const handleSelectionChange = (arr) => {//选择复选框
  console.log('handleSelectionChange[]', arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.pm = item.item1
    formData.value.dw = item.item5
    formData.value.dj = item.item3
    formData.value.jgcpid = item.item6
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
}

const tableRowClick = (row) => {//点击一行跳转
  if (row.item1 == '查无资料') return
  console.log('修改删除',row);
  dialogFormVisible_xgsctj.value=true;  
  formData.value.pm=row.item1//品名
  formData.value.dw=row.item2//单位
  formData.value.sl=(row.item3*1.00==0?'':row.item3)//数量
  formData.value.dj=row.item4//单价
  formData.value.je=(row.item5*1.00==0?'':row.item5)//金额
  formData.value.rq=row.item6//日期
  formData.value.id=row.item7//单id
};

const khjczl_get = async () => {//提交到数据库
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value) 
    .then(function (response) {
      myloading.value = false
      if (response.length <= 0) { return }
      
      switch (formData.value.cztj) {
        case "保存一行发货单":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          setTimeout(() => {
            var zje  = 0
            tableData_xm_gx.forEach((item) => {
              zje  = parseFloat(zje) + parseFloat((item.item5*1.00).toFixed(2))//本单总金额
            });
            formData.value.kd = zje 
            formData.value.pm = ""
            formData.value.sl = ""
            formData.value.dj = ""
            formData.value.je = ""
          }, 300);
          
          break;
        case "保存修改一行发货单":
        case "删除一行发货单":
          tableData_xm_gx.length = 0;
          tableData_xm_gx_base.length = 0;
          response.forEach((item) => {
            tableData_xm_gx.push(item);
            tableData_xm_gx_base.push(item);
          });
          setTimeout(() => {
            var zje  = 0
            tableData_xm_gx.forEach((item) => {
              zje  = parseFloat(zje) + parseFloat((item.item5*1.00).toFixed(2))//本单总金额
            });
            formData.value.kd = zje 
            formData.value.pm = ""
            formData.value.sl = ""
            formData.value.dj = ""
            formData.value.je = ""
          }, 300);
          break;
        case "调用产品基础资料":
          tableData_ckrbb.length = 0;
          tableData_ckrbb_base.length = 0;
          let zje=0
          response.forEach((item) => {
            tableData_ckrbb.push(item);
            tableData_ckrbb_base.push(item);
          });
          break;
      }
      // location.reload();//重新加载本页 
    })
    .catch(function (error) {
    });
};

const toxgsc = () =>{
  router.push({
    path: "dj_fhd_3",
    query: {
      my_row: '',
    },
  });
}
const goBack = () => {
  router.go(-1)
};
const toHome = () => {
  router.push("/");
};

const hh_inputChange = async (e) => {//本页面筛选
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item2.indexOf(inputVal3.value.toUpperCase()) >= 0 ||
      item.item3.indexOf(inputVal3.value) >= 0
    ) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};

</script>
<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

.mybutton {
  display: flex;
  justify-content: center;
}

:deep(.el-collapse-item__header) {
  color: red;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-form-item__label) {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

:deep(.el-collapse-item__content) {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.mybt {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
}

:deep(.el-carousel__container) {
  height: 380px;
}

img {
  height: 380px;
}

.split {
  margin: 6px; margin-bottom: 10px; display: flex;
  justify-content: space-between; align-items: center;
}
</style>